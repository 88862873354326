import React from "react";
import Head from "../components/Head";
import "../globals/global.scss";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Button from "../components/Button";
import "./index.scss";

// Path
const step01Path = "/Step01";

const HomePage = (props) => {
  return (
    <>
      <Head title="DLSIM" />
      <Header />
      <main className="homepage">
        <section>
          <div className="container">
            <div className="text">
              <h1>
                オーダーメイドデスクの
                <br />
                シミュレーター
              </h1>
              <p>
                いくつかの質問に答えるだけで、お客様に最適なデスクが選べます。
                <br />
                また、選択したデスクの費用を簡単にお見積もりすることができます。
              </p>
              <div>
                <Button
                  href={step01Path}
                  dataColor="primary"
                  dataForm="rounded"
                >
                  <span>START</span>
                  <span className="material-icons">chevron_right</span>
                </Button>
              </div>
            </div>
            <div className="img"></div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default HomePage;
